import * as Sentry from '@sentry/sveltekit'
import { handleErrorWithSentry } from '@sentry/sveltekit'

Sentry.init({
	dsn: 'https://99a6e17ef3df43adba0aaa61f402e8b5@o482760.ingest.sentry.io/4505595612102656',
	tracePropagationTargets: [], // disable tracing
	// Reduce how many errors we get
	sampleRate: 0.8,

	// TODO(BF): see https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-thirdpartyerrorfilterintegration
	//   to remove third-party errors

	ignoreErrors: [
		// > When using strings, partial matches will be filtered out. If you need to filter by exact match, use regex patterns instead.
		// https://docs.sentry.io/platforms/javascript/configuration/filtering/

		/^ResizeObserver loop/,

		// Random network changes
		/other side closed/, // socket error
		/Load failed/,
		/fetch failed/,
		/NetworkError when attempting to fetch resource./,
		/network error/,

		// These occur during production releases
		/^Cannot find module/,
		'Failed to fetch dynamically imported module:',
		'Importing a module script failed.',
		'error loading dynamically imported module:',

		// Random error from Outlook scanning links in emails: https://stackoverflow.com/a/76731810/735926
		/Non-Error promise rejection captured with value: Object Not Found Matching Id:\d+, MethodName:update, ParamCount:4/,

		// Random errors that have no effect on navigation
		// - Sentry: SVELTE-FRONT-PROD-F0
		'Failed to execute \'getComputedStyle\' on \'Window\': parameter 1 is not of type \'Element\'',
		'Argument 1 (\'element\') to Window.getComputedStyle must be an instance of Element',
		'Window.getComputedStyle: Argument 1 is not an object.',
		// - Sentry: SVELTE-FRONT-PROD-9M
		'Tried to dipatch event without element.',
	],
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
